//import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function Header() {

    return (
        <header>
            <div class="row">
                <div class="col-xs-12 col-md-2">
                    <div class="photo"><img src={'/assets/img/cbayliss.jpg'} alt="Chris Bayliss" /></div>
                </div> 
                <div class="col-xs-12 col-md-8">
                    <div class="name-title">
                        <div class="name">Christopher Bayliss</div>
                        <div class="title">Deputy Director of Information Technology</div>
                        <div class="location"><FontAwesomeIcon icon={faLocationDot} />&nbsp; Fishers, Indiana</div>
                    </div>
                </div> 
                <div class="col-xs-12 col-md-2">
                    <div class="icons"><a href={'https://www.linkedin.com/in/cbayliss1/'} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>&nbsp; <a href={'/assets/pdf/Christopher-Bayliss.pdf'} download><FontAwesomeIcon icon={faDownload} /></a></div>
                </div>   
            </div>
        </header>
    );
}

export default Header;