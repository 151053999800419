function About() {

    return (
        <section>
            <h2>About</h2>
            <p>A visionary with 15+ years of experience delivering Information Technology solutions to improve efficiency and drive growth. Proficient in building teams, leading projects, budgeting and collaborating with all levels of an organization.</p>
        </section>
    );
}

export default About;