import { useState, useEffect } from 'react';

function Astro() {

    const [visible, setVisible] = useState(false);
    const [count, setCount] = useState(0);

        useEffect(() => {

            if (count < 2) {

                var intervalTime = 500;

                if (visible) { 
                    intervalTime = 2000;
                }

                const intervalId = setInterval(() => {
                    setVisible((visible) => !visible);
                    setCount((count) => count + 1);
                }, intervalTime);
                
                return () => {
                    clearInterval(intervalId);
                };
            }

        });

    return visible ? <div class="astro visible"><img src={'/assets/img/astro.png'} alt="Astronaut" /></div> : <div class="astro hidden"><img src={'/assets/img/astro.png'} alt="Astronaut" /></div>;
}

export default Astro;