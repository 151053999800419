function Experience() {

    return (
        <section>
            <h2>Experience</h2>

            <h4>Deputy Director of Information Technology - The American Legion</h4>
            <p>Jun 2019 - Present</p>
            <p>Exercising cross-functional leadership by managing software engineers, data analysts and support specialists to provide a stellar customer experience. Responsible for hiring talent, managing vendor relationships, budget assistance and migrating services to the cloud.</p>
        
            <hr />
            
            <h4>Web Manager - The American Legion</h4>
            <p>Aug 2010 - Jun 2019</p>
            <p>Hired and managed a team of engineers to architect, develop and maintain online assets. Collaborated with UI/UX specialists and directed initiatives including API and SSO integration, SEO and e-mail marketing.</p>

            <hr />
            
            <h4>Drupal Developer - The American Legion</h4>
            <p>May 2008 - Aug 2010</p>
            <p>Provided front-end and back-end development for online assets.</p>
        </section>
    );
}

export default Experience;