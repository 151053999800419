function Skills() {

    return (
        <section>
            <h2>Skills</h2>
            <div class="skill">Agile</div>
            <div class="skill">Azure</div>
            <div class="skill">Bootstrap</div>
            <div class="skill">CSS</div>
            <div class="skill">Drupal</div>
            <div class="skill">Git</div>
            <div class="skill">HTML</div>
            <div class="skill">JavaScript</div>
            <div class="skill">jQuery</div>
            <div class="skill">Leadership</div>
            <div class="skill">Microservices</div>
            <div class="skill">PHP</div>
            <div class="skill">React</div>
            <div class="skill">Scrum</div>
            <div class="skill">SQL</div>
            <div class="skill">TypeScript</div>
        </section>
    );
}

export default Skills;