function Education() {

    return (
        <section>
            <h2>Education</h2>
            <h4>Indiana University Bloomington</h4>
            <p>Bachelor of Arts - BA, Social Information Architecture, Computer Science, Informatics</p>
            <p>2001-2005</p>
        </section>
    );
}

export default Education;