import './App.css'
import Astro from './components/Astro'
import Header from './components/Header'
import About from './components/About'
import Skills from './components/Skills'
import Education from './components/Education'
import Experience from './components/Experience'
import Footer from './components/Footer'
import Certifications from './components/Certifications'
import { useState, useEffect } from 'react'

function App() {

  const [visible, setVisible] = useState(false);

  useEffect(() => {

      if (!visible) {

          var intervalTime = 5000;

          const intervalId = setInterval(() => {
              document.querySelector('#container').classList.remove('hidden');
              document.querySelector('#container').classList.add('visible');
              setVisible((visible) => !visible);
          }, intervalTime);
          
          return () => {
              clearInterval(intervalId);
          };
      }

  });

  return (
    <div>
      <Astro />
      <div id="container" class="container hidden">
        <Header />
        <div class="row">
          <div class="col-xs-12 col-md-8">
            <About />
            <Skills />
            <Education />
            <Experience />
          </div>
          <div class="col-xs-12 col-md-4">
            <Certifications />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;