function Certifications() {

    return (
        <section>
            <h2>Certifications</h2>

            <h4><a href="https://www.linkedin.com/learning/certificates/e59ebb6b10c67dabb3d6a6890f439476ef636e778899c14de2458af899109778" target="_blank" rel="noreferrer">Agile Project Leadership</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/270a71d1f879396addba36abb73c68f2c7daaf2b4abfad98b8932d260280705d" target="_blank" rel="noreferrer">Designing RESTful APIs</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/4ebc18d1dc8dd702e598aa0845610b345bfba69dec4ba840586a21ead8145f95" target="_blank" rel="noreferrer">Leadership in Tech</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/336c430123434a2ae21b5713527c233f0c10d83a8e9e4e15ccde470c6d624f06" target="_blank" rel="noreferrer">Learning C#</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/bc9c5bca3eb18043ba3d3e5a203044c26f80a66af3e743ffcd1e1791eaf13a74" target="_blank" rel="noreferrer">Learning TypeScript</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/76b4194176f07916c3ac178cb467a0f2ed903e906661fe423c728c53158f8f18" target="_blank" rel="noreferrer">Management Excellence at Microsoft: Model, Coach, Care</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/84704f1846a64b2cb357e83467f8c346e470993d3dce7e91ee793bdca59eaeaf" target="_blank" rel="noreferrer">Node.js Essential Training</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/bccbfabd9bf0584fc3de84ffe65fc09fc81a9d0b67fdd50e0536bf1c5a3adca0" target="_blank" rel="noreferrer">Project Management Skills for Leaders</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/a39adafbc9e4a060ab7373048397feca25a61b25691a02e233a1a3f6be298b0e" target="_blank" rel="noreferrer">React.js Essential Training</a></h4>
            <p>Issued Nov 2022</p>

            <h4><a href="https://www.linkedin.com/learning/certificates/73b5673c8f8bda03a546d2948f6d4992e251a035c6bb57feb6d41cedbcdfcbbf" target="_blank" rel="noreferrer">Scrum: Advanced</a></h4>
            <p>Issued Nov 2022</p>
        </section>
    );
}

export default Certifications;